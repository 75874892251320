import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
        Created with React + Redux
    </div>
  )
}

export default Footer